import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { SnackbarService } from '../service/snackbar.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

    constructor(private snackbarService: SnackbarService,) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // return next.handle(request);
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // App side error
                if (error.error instanceof ErrorEvent) {
                   
                    // display error message 
                    this.snackbarService.error(error.error.message, '', 'top', 4000);
                }
                // Server side error
                else {
                    if (error.status === 500) {
                        //  redirect to error page 
                        this.snackbarService.error('Internal server error!', '', 'top', 4000);
                    } else {
                        // display error message
                        if(error.error.message){
                            this.snackbarService.error(error.error.message, '', 'top', 4000);
                        }else{
                            this.snackbarService.error(error.error.error, '', 'top', 4000); 
                        }
                    }
                }
                // Server stopped error
                if (error.status === 0) {
                    this.snackbarService.error('Somthing went wrong!', '', 'top', 4000);
                }
                return throwError(error.error.message);
                
            })
        );
    }
}
