import { Component } from '@angular/core';
import { LandingService } from './landing/landing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';

  constructor(private lService: LandingService) { }

  ngOnInit() {
    // Check user authentication on every route
    // this.lService.autoAuthUser();
  }
}
