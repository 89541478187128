import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Email, login, loginData } from './landing/access.model';
import { BehaviorSubject } from 'rxjs';

const BACKEND_URL_LOGIN = environment.apiBaseUrl + "/auth/login";
const BACKEND_URL_FORGET = environment.apiBaseUrl + "/auth/forgot-password";
const BACKEND_URL_BROKER = environment.apiBaseUrl + "/broker";
const BACKEND_URL_UPLOAD = environment.apiBaseUrl + "/upload/";
const BACKEND_URL_AUTH = environment.apiBaseUrl + "/auth";
const BACKEND_URL_ORGANIZATION = environment.apiBaseUrl + "/organization";




@Injectable({
  providedIn: 'root'
})
export class LandingService {
  // Set Var
  private isAuthenticated = false;
  private loginData!: loginData
  private authStatusListner = new Subject<boolean>();
  private userId!: string;
  private token!: string;
  public redirectUrl!: any;
  userLoggedIn = new BehaviorSubject(false);

  constructor(private http: HttpClient, private router: Router) { }

  // Get authenticated status
  getIsAuth() {
    return this.isAuthenticated;
  }

  // Listen auth status
  getAuthStatusListner() {
    return this.authStatusListner.asObservable();
  }

  // Get token
  getToken() {
    return localStorage.getItem('token')
  }

  // Get teacher id
  getUserId() {
    return this.userId;
  }




  // Login to app
  access(userData: login) {
    const authData: login = {
      role: userData.role,
      email: userData.email,
      password: userData.password
    }
    return this.http.post<{ message: string, status: boolean, data: loginData }>(BACKEND_URL_LOGIN, authData)
  }


  login(userInfo: any) {
    if (userInfo) {
      localStorage.setItem('userId', userInfo.data._id);
      localStorage.setItem('role', JSON.stringify(userInfo.data.role));
      localStorage.setItem('token', userInfo.data.token);
      localStorage.setItem('isBrokerOrganization',JSON.stringify(userInfo.data.isBrokerOrganization));
      this.userLoggedIn.next(true);
    } else {
      this.userLoggedIn.next(false);
    }
  }

  isUserLoggedIn() {
    if (localStorage.getItem('token') && localStorage.getItem('userId')) {
      this.userLoggedIn.next(true);
    } else {
      this.userLoggedIn.next(false);
    }
    return this.userLoggedIn.value;
  }

  logout() {
    localStorage.clear();
  }


  // forget password
  forgetPassword(email: Email) {
    return this.http.post<{ message: string }>(BACKEND_URL_FORGET, email);
  }


  // Join Us
  joinUs(userData: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_BROKER, userData)
  }

  joinUsAsOrganization(userData: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_ORGANIZATION, userData)
  }

  changePassword(data: any) {
    return this.http.post<{ message: string, exams: any, maxExams: number }>(BACKEND_URL_AUTH + '/change-password', data);
  }


  // Update
  joinUpdate(userData: any, userId: string) {
    return this.http.put<{ message: string, status: boolean, data: any }>(BACKEND_URL_ORGANIZATION + '/' + userId, userData)
  }
  
  // Update
  joinUpdateIndidual(userData: any, userId: string) {
    return this.http.put<{ message: string, status: boolean, data: any }>(BACKEND_URL_BROKER + '/' + userId, userData)
  }

  // File Upload
  fileUpload(file: File | string, userId: string) {
    const queryParams = `?userId=${userId}`;
    const formData: FormData = new FormData();
    formData.append("file", file);
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_UPLOAD + queryParams, formData,)
  }

  public getCountry(){
    return this.http.get<{ message: string, status: boolean, data: any }>('assets/JSON/countries.json').pipe(
      map((res: any) => {
        console.log('jjjjj',res)
        return res;
      })
    )
  }

  sentOtpWhatsapp(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/send-otp-whatsapp', data);
  }

  verifyWhatsapp(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/verify-otp-whatsapp', data);
  }

  sentOTPphone(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/request-otp', data);
  }

  verifyPhone(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/verify-otp', data);
  }

  sentOTPemail(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/send-otp-email', data);
  }

  verifyEmail(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/verify-otp-email', data);
  }

  sentOTPemailForForgetPswrd(data: any) {
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/forgot-password', data);
  }

  setNewPasswrd(data: any){
    return this.http.post<{ message: string, status: boolean, data: any }>(BACKEND_URL_AUTH + '/reset-password', data);
  }

}