import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LandingService } from 'src/app/landing/landing.service';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {

  constructor(private lService: LandingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);
    // const authToken = this.lService.getToken();
    const authToken = localStorage.getItem('token')
    let secretKey = request.headers.get("Authorization");

    if (secretKey && !authToken) {
        const token = secretKey.split(" ")[1];
        const authRequest = request.clone({
            headers: request.headers.set("Authorization", "Bearer " + token)
        })
        return next.handle(authRequest);
    } else {
        const headers = new HttpHeaders({
            'Authorization': "Bearer " + authToken,
            'Username': ''
        });
        const authRequest = request.clone({ headers });
        return next.handle(authRequest);
    }
  }
}
