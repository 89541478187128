import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LandingService } from 'src/app/landing/landing.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  constructor( private router: Router, private landingService: LandingService) { }
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //     const isAuth = this.landingService.getIsAuth();
  //     if (!isAuth) {
  //       this.router.navigate([""]);
  //     }
  //     return isAuth;
  // }

  canActivate():boolean{
    if(!this.landingService.isUserLoggedIn()){
      this.router.navigate([''])
    }
    return true;
  }
  
}